import type { Location } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { useState } from "react";
import { FormField } from "../../components/forms/FormField.tsx";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useConfig } from "../../config.ts";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { CountrySelect } from "../../modules/location/CountrySelect.tsx";
import { getIso2CountryInfo, getIso3CountryInfo } from "../../modules/location/locationCountry.ts";
import { locationCreateMutation, locationUpdateMutation } from "../../modules/location/query.ts";
import { AInput } from "../form/Fields.tsx";

export function AddLocationButton({ button }: { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditLocationModalContent />
        </WModal>
    );
}

export function EditLocationButton({ item, button }: { item: Location; button: ReactNode }) {
    return (
        <WModal button={button}>
            <AddEditLocationModalContent item={item} />
        </WModal>
    );
}

export function AddEditLocationModalContent({ item }: { item?: Location }) {
    const { siteCountry } = useConfig();
    const createMutation = useStandardMutation(locationCreateMutation);
    const updateMutation = useStandardMutation(locationUpdateMutation);

    const defaultCountryCode = item?.country_code ?? notNull(getIso2CountryInfo(siteCountry)?.code.iso3);

    const [order, setOrder] = useState<string | number>(item?.order_nr ?? "");
    const [slug, setSlug] = useState<string>(item?.slug ?? "");
    const [name, setName] = useState<string>(item?.display_name ?? "");
    const [description, setDescription] = useState<string>(item?.description ?? "");
    const [isCreateDisabled, setIsCreateDisabled] = useState<boolean>(!!item?.create_resource_disabled);
    const [isPreferred, setIsPreferred] = useState<boolean>(item?.is_preferred ?? false);
    const [isPublished, setIsPublished] = useState<boolean>(item?.is_published ?? false);
    const [countryCode, setCountryCode] = useState<string>(defaultCountryCode);

    const country = getIso3CountryInfo(countryCode);
    const isEditMode = !!item;

    async function onSubmit() {
        if (isEditMode) {
            await updateMutation.mutateAsync({
                body: {
                    order_nr: Number(order),
                    display_name: name,
                    description: description,
                    create_resource_disabled: isCreateDisabled,
                    is_preferred: isPreferred,
                    is_published: isPublished,
                    country_code: countryCode,
                },
                location: slug,
            });
        } else {
            await createMutation.mutateAsync({
                body: {
                    order_nr: Number(order),
                    display_name: name,
                    description: description,
                    create_resource_disabled: isCreateDisabled,
                    is_preferred: isPreferred,
                    is_published: isPublished,
                    country_code: countryCode,
                },
                location: slug,
            });
        }
    }

    function changeCountry(code: string) {
        setCountryCode(code.toLowerCase());
    }

    function changeCreateDisabled(value: boolean) {
        setIsCreateDisabled(value);
        if (value) setIsPreferred(false);
    }

    return (
        <WModalContent
            isActionDisabled={!name || !description || !slug || !countryCode}
            title={isEditMode ? "Edit Location" : "Add New Location"}
            label={isEditMode ? "Edit" : "Add"}
            modalAction={async () => await onSubmit()}
        >
            <WTextField autoFocus className="max-w-24rem" wide label="Order Nr" onChange={setOrder}>
                <AInput value={order} />
            </WTextField>

            <WTextField isReadOnly={isEditMode} wide label="Slug" onChange={setSlug}>
                <AInput value={slug} />
            </WTextField>

            <WTextField wide label="Name" onChange={setName}>
                <AInput value={name} />
            </WTextField>

            <WTextField wide label="Description" onChange={setDescription}>
                <AInput value={description} />
            </WTextField>

            <FormField label=" " wide>
                <WCheckbox isSelected={isCreateDisabled} onChange={(value) => changeCreateDisabled(value)}>
                    Create Resource Disabled
                </WCheckbox>
            </FormField>

            <FormField label=" " wide>
                <WCheckbox isDisabled={isCreateDisabled} isSelected={isPreferred} onChange={setIsPreferred}>
                    Is Preferred
                </WCheckbox>
            </FormField>

            <FormField label=" " wide>
                <WCheckbox isSelected={isPublished} onChange={setIsPublished}>
                    Is Published
                </WCheckbox>
            </FormField>

            <FormField label="Country" wide>
                <CountrySelect valueKey={country?.code.iso2} onChange={(country) => changeCountry(country.code.iso3)} />
            </FormField>
        </WModalContent>
    );
}
